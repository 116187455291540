
.accordion {
  li {
    .wrapper {

      position: relative;


      &:before,
      &:after {
        @apply .inline-block .absolute .bg-lightblue;

        height: 100%;
        content: " ";
        width: 2rem;
        position: absolute;
        top: 0;
      }

      &:before {
        left: -2rem;
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
      }

      &:after {
        right: -2rem;
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
      }

      .toggle {
        display: none;
      }

      .faqtitle {

        .textsize{
          font-size: 1.5rem ;
          line-height: 1.4 ;
          margin-bottom: 0 ;
          @apply .font-bold .font-title;
          @media (max-width: 900px) {
            font-size: 3rem;
            line-height: 1.25;
          }
          @media (max-width: 700px) {
            font-size: 4rem;
        
          }
        }

        svg {
          transition: all 0.2s;
        }

        .accordian-arrow{
          @media (max-width: 1024px) {

          }

        }

        &:active {
          top: 1px;
        }
      }

    


      .response {
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.2s;
        max-height: 0;
        overflow: hidden;
        .general-content-area{
          ul{
            float: left;
            margin-bottom: 1rem;
            li{
              ul{
                float: left;
                margin-bottom: .5rem;
              }
            }
          }
        }
      }

      .toggle:checked {

        &+.faqtitle,
        &+.faqtitle+.response {}

        &+.faqtitle+.response {
          max-height: fit-content;
        }

        &+.faqtitle svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}
