table{
  @apply .border-collapse .w-full .border-lightblue .border-8 .mt-10 .mb-12;
  thead{
    tr{
      &.header{
        @apply .py-4 .px-6 .bg-navy .text-white .border .text-left .border-lightblue .flex .table-row .flex-row .flex-no-wrap .mb-0;
        @extend .title-small;
        th{
          @apply .text-left .w-auto .py-4 .px-6  .border .border-b .table-cell .static ;
        }
      }
    }

  }

  tbody{

    tr{
      &.body{
        @apply .bg-white .flex .table-row .flex-row .flex-no-wrap .mb-0;
        td{
          @apply .text-left .w-auto .py-4 .px-6  .border .border-b .text-grey .table-cell .static ;
          h5{
            @apply .text-navy;
            @extend .title-small;
          }
        }
      }
    }
  }
  p{
    margin: 0;
  }

}
