.mobile-menu-wrap {
  max-width: 354px;
  padding:  50px;
  .top {
    img {
      max-width: 200px;
    }
  }
  .mid {
    li{
      width:100%;
      display: block;
      a, .sub-link{
        color:#fff;
        border-bottom: 1px solid #324d65;
        padding: 2rem 0;
        max-width:100%;
        display: flex;
        width: 100%;
        font-family: $title-font-family;
        .icon{
          @media (max-width: 900px) {
            @apply .text-white;
            border-radius: .7rem 0 0 .7rem ;
          }
          svg{
            @apply .overflow-visible .fill-current .text-white;
            @media (max-width: 900px) {
              @apply .w-12 .h-12;
            }
          }

        }
      }
      ul{
        li{
          a{
            padding: 2rem 0 2rem 0rem;
            ::before{
              content: "-";
            }
            &.viewalllink{
              @apply .text-blue .underline;
              font-size: 2.5rem;
            }
          }
        }

      }
      .subMenu{
        transform: translateZ(0);
        transition: all 1s;
      }


    }
  }
}
