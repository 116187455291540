.form-wrapper {

  label {
    @apply block font-bold;
    font-family: $title-font-family;
    color: #333;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    @media (max-width: 960px) {
      margin-top: 2.75rem;
      margin-bottom: 1.3rem;
    }
    &.desc{
      margin-bottom: 0;
    }
  }


  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="file"],
  textarea {
    @apply bg-white block w-full mb-5;
    border-radius: .25rem;
    padding: 1.1rem;
    color: #333;
    border: 1px solid #D2D2D2;

    @media (max-width: 960px) {
      padding: 1.6rem;
    }
    &:focus {
      outline: none;
    }
    &::placeholder{
      color:#888888;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    -webkit-appearance: none;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
  }

  .form-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5%;

    .form-item {
      padding: 0 1.1rem;
      width: 100%;

      &.third {
        width: 33.333%;
      }

      &.half {
        width: 50%;
      }
    }
  }

  textarea {
    min-height: 184px;
    padding: 22.5px;
  }

  .helper-txt {
    margin-top: 2.5rem;
    font-size: 1rem;
    color: #787878;
    line-height: 1.5;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 640px) {
      font-size: 1rem;
    }
  }

  .terms-wrap-chk {
    @apply my-10;
    font-weight: 600;
    font-size: 14px;
    margin-left: -2.5rem;
    display: flex;

    input {
      margin-left: 2.5rem;
      margin-right: 0.5rem;
      font-size: 30px;

    }
    @media (max-width: 700px) {
      display: block;
      div{
        width: 100%;
        display: block;
        padding-bottom: 2rem;
      }
    }
  }

  .chk-lbl {
    font-weight: 600;
    font-size: 14px;
    color: #4a4a4a;
    padding-left: 15px;
  }

  button.btn {
    @apply font-bold;
  }
}

.validation-msg {
  @apply border-l-4 p-3;
}

.fancybox-content {
  width: 90%;
  margin: 0 auto;
  max-width: 600px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.27);

  .fancybox-button {
    background: none;
    color: #bbb;
    outline: none;
    position: relative;

    &:active {
      top: 1px;
    }

    &:hover {
      @apply text-pink;
    }
  }
}


.sidecard {
  label {
    display: none;
  }
}

.search-form{
  overflow: hidden;
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  margin-bottom: 3rem;
  margin-top: 1rem;
  form{
    width: 100%;
    display: flex;
    input{
      width: 80%;
      background: #fff;
      padding: 1rem;
    }
    button{
      width: 20%;
      border-radius: 0;
    }
  }

}


.selectbasic{
  width: 100%;
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;
  padding: 1.1rem;
  color: #333;
  border: 1px solid #D2D2D2;
  select{
    width: 100%;
  }
}

input:invalid,
textarea:invalid {
  display: inline-block;
}