.inner-wrap {
  max-width: 1300px;
  width: 86%;
  margin: auto;
}

.inner-wrap-small {
  max-width: 830px;
  width: 86%;
  margin: auto;
}
.inner-wrap-xsmall {
  max-width: 600px;
  width: 86%;
  margin: auto;
}

.inner-wrap-md {
  max-width: 920px;
  width: 86%;
  margin: auto;
}


.grid{
  @apply .flex .flex-wrap;

  &[data-columns="2"] {
    @apply .-mx-8;
    > div {
      @apply .w-1/2 .px-8;
      @media (max-width: 900px) {
        @apply .w-full;
      }
    }
  }
  &[data-columns="3"] {
    @apply .-mx-6;
    > div {
      @apply .w-1/3 .px-6;
      @media (max-width: 900px) {
        @apply .w-full .mb-32;
      }
    }
    .smallmargin{
      margin-bottom: 2rem;
      @media (max-width: 900px) {
        margin-bottom: 4rem;
      }
    }
  }
  &[data-columns="4"] {
    @apply .-mx-3;
    @media (max-width: 900px) {
      @apply .-mx-6;
    }
    > div {
      @apply .w-1/4 .px-3;
      @media (max-width: 900px) {
        @apply .w-1/2 .mb-12 .px-6;
      }
    }
  }
  &[data-columns="5"] {
    @apply .-mx-3;
    @media (max-width: 900px) {
      @apply .-mx-6;
    }
    > div {
      @apply .w-1/5 .px-3;
      @media (max-width: 900px) {
        @apply .w-1/2 .mb-12 .px-6;
      }
    }
  }
  &[data-columns="all"] {
    @apply .-mx-3;
    @media (max-width: 900px) {
      @apply .-mx-6;
    }
    > div {
      @apply .flex-1 .px-3;
      @media (max-width: 900px) {
        @apply .w-1/2 .mb-12 .px-6;
      }
    }
  }
  &.nomargin{
    @apply .mx-0;
    > div {
      @apply .px-0;
    }
  }
}
