.embed-body-images {
  margin: 1rem -15px 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 450px) {
    flex-direction: row;
  }
  a {
    display: inline-block;
    margin: 15px;
    width: calc(33% - 30px);
    height: auto;
    max-height: 12rem;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      border-radius: .2rem;
    }
    @media (max-width: 450px) {
      width: calc(100% - 30px);
    }
  }
  &[data-images-count="1"] {
    a {
      width: calc(100% - 30px);
      height: auto;
      max-height: 32rem;
    }
  }
  &[data-images-count="2"] {
    a {
      width: calc(50% - 30px);
      height: auto;
      max-height: 25rem;
    }
  }
}
