

.search-input{
  @apply .flex .mb-0 .items-center .h-full .border-r-2 .border-gray-300 ;
    width: 33.333%;
    max-width: 33.333%;
   &:last-child{
     @apply .flex .border-0;
   }
   &.hide{
     display:none;
   }
   &.location-input{
     flex-grow: 1;
     max-width: inherit;
   }
   @media (max-width: 900px) {
      @apply .flex .mb-12 .w-full .bg-white .border-0;
      border-radius: 0.7rem;
      height: 8rem;
      min-width: 100%;
   }
   @media (max-width: 700px) {
      height: 12rem;
   }
   .search-icon{
     width: 4rem;
     text-align: center;
     @media (max-width: 900px) {
        width: 8rem;

     }
     i{
       font-size: 1.4rem;
       padding-top: .1rem;
       @media (max-width: 900px) {
         font-size: 3rem;
       }
     }
   }
   .location{
     padding: 0 !important;
     border: none;
     font-weight:600;
     font-size: 1rem;
     background-color: none  !important;
     @media (max-width: 900px) {
       font-size: 2.4rem;
     }
     @media (max-width: 700px) {
       font-size: 3.4rem;
     }
   }

}

.edd-root {
  font-weight:600;
  color:#888888;
}

.property-search-frm {
  input[type="text"] {
    border:0;
    margin:0;
    @media (max-width: 900px) {
      width: 88%;
    }

  }
  .edd-root {
    width:100%;
    &::after {
      left: -3.2rem;
      width: 116%;
      @media (max-width: 1500px) {
        z-index: 1000;
        height: .25rem;
        width: 117%;

      }
      @media (max-width: 1050px) {


      }
      @media (max-width: 900px) {
        display: none
      }
    }
    .edd-head {
      height:100%;
      border-bottom:0;
      .edd-value {
        display: inline-block;
        width:80%;
        height:100%;
        line-height: 3;
        padding-right:1rem;
        font-size: 1rem;
        text-overflow: ellipsis;
        @media (max-width: 1150px) {
          line-height: 2.5;
        }
        @media (max-width: 900px) {
          font-size: 2.4rem;
          width: 88%;
        }
        @media (max-width: 700px) {
          font-size: 3.4rem;
        }
      }
      .edd-arrow {
        position: relative;
        display: inline-block;
        top:auto;
        right:auto;
        color:#888888;
      }
    }
    .edd-body {
      left:-3.3rem;

      @media (max-width: 1050px) {
        top: 3.8rem;

      }
      .edd-option {
          position: relative;
          padding: 1rem 1.5rem;
          font-size: 1rem;
          @media (max-width: 1050px) {
            font-size: 1.2rem;
          }
          &:hover{
            @apply .bg-lightblue;

          }
          &[title="View all"] {
            font-size: .9rem;
            line-height: 1.25;
            padding: 1.2rem 1.5rem 1.7rem;
            @apply .font-bold .font-body .text-blue .lowercase .underline;
            &::before{
              display: none;
            }
          }
        }
    }
  }
}
