
.case-study-small-img {
  position: relative;
  top: -3rem;
  @media (max-width: 960px) {
    top: -5rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }

  .round-img {
    border-radius: 100%;
  }
}

.slick-slide {
  &:focus {
    outline: none;
  }
}

.case-study-item {
  .customer-testimonials {
    background: #fff2f2;
    border-radius: 10px;
    height: fit-content;
    min-height: 350px;
    padding: 0 6%;
    width: 90%;
    margin: 3rem 3%;

    p {
      padding: 0 30px 0 30px;
    }
  }

  .slick-active {
    .customer-testimonials {
      background: #ffffff;
      box-shadow: 0 4px 12px 2px #ffeaea;
    }
  }
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 0;

  .testimonialpagination {
    width: fit-content;

    ul.slick-dots {
      position: relative;
      bottom: 4px;
      line-height: 0;
      padding: 2px 15px 0 15px;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        background: none;

        button {
          position: absolute;
          top: 6px;
          left: 6px;
          height: 1px;
          width: 1px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          background: #20323f;
          margin: 0;
          padding: 5px;
          cursor: pointer;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: #e72076;
          }
        }
      }
    }
  }

  #nxt-btn,
  #prev-btn {
    width: auto;
    height: 16px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: #20323f;

    .fa-arrow-left,
    .fa-arrow-right {
      &::before {
        font-size: 18px;
      }
    }
  }
}
