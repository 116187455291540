.logo-items{
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -1.5rem 0 -1.5rem;
    padding: 4rem 0;
    &.flex-wrap{
        flex-wrap: wrap
    }
    
    @media (max-width: 1024px) {
        flex-wrap: wrap;
      }
    .logo-item{
        max-width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 1.5rem ;
        &.morethanfive{
            width: 20%;
            @media (max-width: 1024px) {
                width: 33.3%;
                padding: 0 1.5rem 0 1.5rem;
            }
            @media (max-width: 600px) {
            width: 50%;
            padding: 0 1.5rem 0 1.5rem;
            }
        }

        img{
            width: 100%;
        }
        @media (max-width: 1024px) {
            width: 33.3%;
            padding: 0 1.5rem 0 1.5rem;
          }
          @media (max-width: 600px) {
            width: 50%;
            padding: 0 1.5rem 0 1.5rem;
          }
    }

}

.details_page{
    border-radius: 0.25rem;
    margin-bottom: 3rem;
    .logo-items{
        
        width: 100%;
        flex-wrap: wrap;
        margin: 0 -1.5rem 3rem -1.5rem;
        justify-content: center;
        align-items: center;
        .logo-item{
            max-width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            padding: 0 1.5rem 3rem 1.5rem;
            width:33.3%;
            &.single{
                width:60%;
                padding: 0 1.5rem ;
            }
            &.two{
                width:40%;
                padding: 0 1.5rem ;
            }
            @media (max-width: 600px) {
                width: 50%;
                padding: 0 1.5rem 3rem 1.5rem;
            }
        }
    }
}