.complaints-form{
    display: flex;
    margin: 4rem 0;
    @media (max-width: 960px) {
       flex-direction: column;
      }
    .general-content-area{
        width: 30%;
        margin-right: 2rem;
        padding-top: 45px;
        @media (max-width: 960px) {
            width: 100%;
            margin-right: 0;
        }
    }
    .form-outter{
        width:calc(70% - 2rem);
        background-color: #27789b;
        border-radius: 6px;
        padding: 40px;
        color: #fff;
        @media (max-width: 960px) {
            width: 100%;
            padding: 20px;
        }
        h3{
            font-size: 36px;
            line-height: 1.25;
            font-weight: 700;
            font-family: filson-soft;
            margin-bottom: 1.5rem;
        }
        p{
            font-size: 20px;
            margin-bottom: 15px;
        }

        .btn{
            margin-bottom: 20px;
        }
        .form-inner{
            background-color: #fff;
            border-radius: 6px;
            margin-bottom: 30px;
            margin-top: 35px;
            padding-bottom: 15px ;

            .form-item {
                
                @media (max-width: 960px) {
                    padding: 0 20px;
                }
            }
            h4{
                color: #fff;
                font-size: 26px;
                line-height: 1.25;
                font-weight: 700;
                font-family: filson-soft;
                background-color: #EE1D8F;
                width: 100%;
                padding: 15px;
                border-radius: 6px 6px 0 0;
                @media (max-width: 960px) {
                    font-size: 20px;
                }
            }
            
            .complaincheck{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding: 0 1.3rem 0.5rem;
                margin-bottom: 10px;
                label{
                    margin-left: 15px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            .edd-root{
                width:100%;
                margin-bottom: 1.25rem;
                border-radius: 0.25rem;
                padding: 1.1rem;
                color: #333;
                border: 1px solid #D2D2D2;
            }
            .edd-value{
                padding: 0;
            }
            .edd-head{
                border-bottom: none;
            }
            p{
                color: #000;
                font-size: 18px;
                padding: 30px 0 25px;
                a{
                    color: #EE1D8F;
                    text-decoration: underline;
                }
            }
            .label-p{
                margin-bottom: 10px;
                label {
                    margin-bottom: 0;
                }
                p{
                    padding: 0;
                    margin-bottom: 0;
                }
            }

            .val-error{
                color: #ee1d21;
            }

            .val-error-input{
                border-color:#ee1d21 ;
            }

            [type="checkbox"], [type="radio"] {
                width: 20px;
                height: 20px;
                box-shadow: none;
            }

            [type="text"]{
                margin-bottom: 0.25rem;
            }

        }
    }
}

.loading_gif{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
        width: 100%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    img{
        padding-top: 40px;
        padding-bottom: 0;
    }
}

[x-cloak] { display: none !important; }