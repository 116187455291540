.sidecard{
  @apply .rounded-md .overflow-hidden;
  .side-inner{
    @apply .pt-6 .pb-2 .px-8;
    @media (max-width: 900px) {
      padding: 4rem;
    }
  }
  h2{
    @apply .font-bold .font-title .text-pink .pt-4 .mb-5;
    font-size: 1.75rem;
    line-height: 1.25;
    @media (max-width: 900px) {
      font-size: 4rem;
      margin-bottom: 2rem;
    }
    @media (max-width: 700px) {
      font-size: 5rem;
    }
  }


}

.downloadcard{
  @apply .rounded-md .overflow-hidden .bg-blue;
  padding: 2rem;
  @media (max-width: 900px) {
    padding: 4rem;
  }
  .download-inner{

    h2{
      @apply .font-bold .font-title .text-white .mb-5;
      font-size: 1.75rem;
      line-height: 1.25;
      @media (max-width: 900px) {
        font-size: 4rem;
        margin-bottom: 2rem;
      }
    }
    .btn-download{
      margin-top: 1rem;
      &:first-child{
        margin-top: 0;
      }

    }
  }



}
