.card-download{
  @apply .mb-12;
  min-height: 12rem;
  .image{
    @apply .w-1/3 .bg-pink flex;
    border-radius: .4rem 0 0 .4rem ;
    position: relative;
    overflow: hidden;
    @media (max-width: 900px) {
      border-radius: .7rem 0 0 .7rem ;
    }
    img{
      right: 0;
      bottom: 0;

    }
    &.rotate{
      img{
        transition: transform .5s ease-in-out;
        transform: rotate(-16deg);
        width: 9rem;
        height: 12rem;
        right: 0rem;
        top: 2rem;
      }
    }

  }
  .text{
    @apply .w-2/3 .bg-lightgray .p-8;
    border-radius:0 .4rem .4rem 0;
    @media (max-width: 900px) {
      @apply .p-16;
      border-radius:0 .7rem .7rem 0;
    }
    h4{
      @apply .text-pink;
      margin-bottom: 0.2rem;
      @media (max-width: 900px) {
        font-size: 2rem;
      }
    }
    h3{
      padding-top: 0;
      margin-bottom: 0.5rem;
      text-transform: capitalize;
      @media (max-width: 900px) {
        font-size: 3rem;
      }
    }
    .link{
      @apply .underline .text-navy .font-bold;
      @media (max-width: 900px) {
        font-size: 2rem;
      }
    }
  }
  &:hover{
    .image{

      &.rotate{
        img{
          transform: scale(1.1) rotate(-18deg);
        }
      }

    }
  }
}
