.card{
  @apply .rounded-md .overflow-hidden .shadow .bg-white .relative;
}

.partnerlogo{
  height: 9.5rem;
  overflow: hidden;
  @media (max-width: 900px) {
    height: 20rem;
  }
  @media (max-width: 700px) {
  }
  a{
    display: flex;
    height: 100%;
    .img-logo{
      object-fit: contain;
      padding: 2rem 2rem 1rem 2rem;
      margin: 0 auto;
      @media (max-width: 900px) {
        padding: 3rem 3rem 1.5rem 3rem;

      }
    }
  }
  &.sidebar{
    background: #fff;
    border-radius: .5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    height: 15.5rem;
    @media (max-width: 900px) {
      height: 40rem;
    }
    a{

      .img-logo{
        object-fit: contain;
        padding: 3rem ;
        margin: 0 auto;
        @media (max-width: 900px) {
          padding: 4rem;
        }
      }
    }
  }
}

.partner-textarea{
  padding: 2rem 1.5rem;
  @media (max-width: 900px) {
    padding: 4rem 3rem;

  }
}

.tick-list{
  @apply .rounded-md .flex .flex-wrap .shadow .bg-white .relative .h-full ;
  .tick_inner{
    @apply .flex .items-center .relative .h-full ;
    padding: 1.5rem;
    @media (max-width: 900px) {
      padding: 3rem;
      min-height: 14rem;
    }
    .icon_tick{
      width: 2rem;
      height: 2rem;
      left: -1rem;
      position: absolute;
      @media (max-width: 900px) {
        width: 4rem;
        height: 4rem;
        left: -2rem;
      }
    }
    h2{
      font-size: 1.05rem;
      line-height: 1.25;
      @apply .font-bold .font-title;
      @media (max-width: 900px) {
        font-size: 2rem;
      }
    }
    p{
      font-size: .9rem;
      line-height: 1.25;
      @apply .font-semibold .font-body .text-grey .mt-2 .mb-1;
      @media (max-width: 900px) {
        font-size: 2rem;
      }
    }
  }
}

.min-height-property{
  min-height: 9rem;
}

.search-result{
  h3{
    margin-bottom: 0.5rem;
  }
}
