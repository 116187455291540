
.r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;

}

.r-tabs-anchor {



}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none;
    text-align: center;
    padding: 1rem 1.5rem;
    border-right: 1px solid #ECF6FA;
    border-top: .3rem solid #DDEDF4;
}
.r-tabs-tab {
    &.r-tabs-state-active{
      border-top: .3rem solid;
      @apply .border-blue;
      border-right: 1px solid #ECF6FA;
    }
    &:first-child{
      border-left: 1px solid #ECF6FA;
    }
}

.r-tabs .r-tabs-panel {
    display: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs-state-active {
    background-color: white;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

@media only screen and (max-width: 900px) {
    .r-tabs .r-tabs-nav {
        display: none;
    }

    .r-tabs {
        background-color: white;

        &:last-child {
            border-bottom: 0;
        }

        .r-tabs-accordion-title {
            color: #ffffff;
            display: block;
            padding: 2rem;
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 700;
            letter-spacing: 1.25px;
            border-bottom: 1px solid #ECFCFC;
            width: 94%;
            margin: 0 auto;
            margin-bottom: 1.5rem;
            font-family: $title-font-family;
            @apply .bg-navy;
            border-radius: .4rem ;

            @media (max-width: 900px) {
              border-radius: .7rem ;
              padding:  4rem;
              font-size: 3rem;
            }
            a{
              width: 100%;
              display: block
            }


        }

        .r-tabs-state-active {

        }
    }
}
