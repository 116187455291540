footer {

  .footeritems{
    @apply .w-1/6 .pt-20 .pl-6;
    &:last-child{
      @apply .pr-0;
    }
    ul{
      li{
        &:first-child{
          @apply .mb-3;
          a{
            @extend .title-card;
            @apply .text-white;
          }
        }
      }
    }
  }
}
