.notification{
  @apply .bg-pink;
  text-align: center;
  padding: .7rem 1rem;
  color: #fff;
  p{
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }
  a{
    text-decoration:underline;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 1200px) {
     padding: 1rem;
    p{
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  @media (max-width: 900px) {
     padding: 1rem;
    p{
      font-size: 2rem;
      font-weight: 600;
    }
  }
  @media (max-width: 600px) {
     padding: 1rem;
    p{
      font-size: 2.5rem;
      font-weight: 600;
    }
  }
}
