.pagination {
    margin: 0;
    position: relative;
    text-align: left;
    width: 100%;
    .pagenumber{
      display: flex;
      margin-left:.5rem;
      @media (max-width: 900px) {
        display: none;
      }
      >li {
          // display: inline-block;
          margin-right:.5rem;


          &.previous,
          &.next,
          &.active,
          &.active+li {
              display: inline-block;
          }
          &.previous,
          &.next {
              a {
                  padding: 6px 0;
              }
          }
          &.previous {
              left: 0;
          }
          &.next {
              right: 0;
          }
          &.disabled {
            color: #333;
            padding: 0 1rem;
            display: flex;
            align-items: flex-end;
            font-size: 2rem;
            line-height: 2rem;
            span{

            }
          }


          >a {


              &,
              &:hover {

              }
          }
      }
    }
    .pagemobile{
      @apply  .text-white .inline-block .rounded .font-extrabold .text-grey;
      padding: 0.8rem .2rem;
      font-size: 0.9rem;
      display: none;
      @media (max-width: 900px) {
        display: block;
        font-size: 2.4rem;
        padding: 1.8rem 2.4rem;
        border-radius: 0.5rem;
        @apply .font-bold;
      }
      @media (max-width: 700px) {
        font-size: 3.2rem;
      }
    }



    .arrow {
        color: red;
        font-size: 26px;
        line-height: 0;
        vertical-align: -2px;
    }

    .previous .arrow {
        margin-right: 5px;
    }

    .next .arrow {
        margin-left: 5px;
    }
}
