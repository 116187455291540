.video-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  height: 0;

  &.paused-state {
    .play {
      display: flex;
    }
  }
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: -1px;
  width: 100%;
  height: 100%;
}

video{
  width: 100%;
}
