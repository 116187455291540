.slideshowtitle {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 2.4rem;
  line-height: 1.25;
  @media (max-width: 1600px) {
    font-size: 2.3rem;
    line-height: 1.25;
  }
  @media (max-width: 1300px) {
    font-size: 2.6rem;
    line-height: 1.25;
  }
}



p, .generaltext, .general-content-area {
  font-size: 1.05rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  @media (max-width: 1300px) {
    font-size: 1.2rem;
  }

  @media (max-width: 900px) {
    font-size: 2.7rem;
    margin-bottom: 6rem;
  }
  @media (max-width: 700px) {
    font-size: 4rem;
  }

}



.title-banner{
  font-size: 2.4rem;
  line-height: 1.25;
  @apply .font-bold .font-title;
  @media (max-width: 1600px) {
    font-size: 2.3rem;
    line-height: 1.25;
  }
  @media (max-width: 1300px) {
    font-size: 2.6rem;
    line-height: 1.25;
  }
  @media (max-width: 900px) {
    font-size: 5rem;
    line-height: 1.25;
  }
  @media (max-width: 700px) {
    font-size: 6rem;
    line-height: 1.25;
  }
}
.title-banner-sub{
  font-size: 1.5rem;
  line-height: 1.25;
  @apply .font-bold .font-body;
  @media (max-width: 900px) {
    font-size: 3rem;
    line-height: 1.25;
  }
  @media (max-width: 700px) {
    font-size: 4rem;
    line-height: 1.25;
  }
}
.title-general{
  font-size: 2.2rem;
  line-height: 1.25;
  @apply .font-bold .font-title;
  @media (max-width: 900px) {
    font-size: 6rem;
    line-height: 1.25;
  }
  @media (max-width: 700px) {
    font-size: 7rem;
    line-height: 1.25;
  }
}
.title-general-sm{
  font-size: 2rem;
  line-height: 1.25;
  @apply .font-bold .font-title;
  @media (max-width: 900px) {
    font-size: 5.5rem;
    line-height: 1.25;
  }
  @media (max-width: 700px) {
    font-size: 6.5rem;
    line-height: 1.25;
  }
}
.title-medium{
  font-size: 1.9rem;
  line-height: 1.25;
  @apply .font-bold .font-title;
  @media (max-width: 900px) {
    font-size: 5rem;
    line-height: 1.25;
  }
  @media (max-width: 700px) {
    font-size: 7rem;
    line-height: 1.25;
  }
}
.title-card{
  font-size: 1.5rem !important;
  line-height: 1.4 !important;
  margin-bottom: 0 !important;
  @apply .font-bold .font-title;
  @media (max-width: 900px) {
    font-size: 3rem;
    line-height: 1.25;
  }
  @media (max-width: 700px) {
    font-size: 4rem;

  }
}
.title-small{
  font-size: 1.05rem;
  line-height: 1.25;
  @apply .font-bold .font-title;
  @media (max-width: 900px) {
    font-size: 3rem;
    line-height: 1.25;
  }

}

.title-highlight-box{
  @apply .font-bold .font-title .text-pink .text-3xl  .mb-5;
  @media (max-width: 900px) {
    font-size: 3rem;
  }
}



.generaltext{
  h2{
    @extend .title-general;
    margin-bottom: 2rem;
    @media (max-width: 900px) {
      margin-bottom: 4rem;
    }
  }
  P{
    margin-bottom: 1.5rem;
    @media (max-width: 900px) {
      margin-bottom: 6rem;
    }
  }
  a{
    text-decoration: underline;
  }
}

.general-content-area{
  h1{
    @extend .title-general;
    margin-bottom: 1.5rem;
    @apply .text-blue .pt-2;

  }
  h2{
    @extend .title-general-sm;
    margin-bottom: 1.5rem;
    @apply .text-blue .pt-2;
    @media (max-width: 900px) {
      margin-bottom: 4rem;
    }
    &.text-white{
      @apply .text-white;
    }
    &.staff-title{
      margin-bottom: 0.3rem;
    }
  }
  h3{
    margin-bottom: 1.5rem;
    @apply .text-navy .pt-2;
    font-size: 1.5rem;
    line-height: 1.25;
    @apply .font-bold .font-title;
    @media (max-width: 900px) {
      font-size: 3.5rem;
      line-height: 1.25;
    }
    @media (max-width: 700px) {
      font-size: 5rem;
      line-height: 1.25;
    }
    &.staff-position{
      @apply .text-pink;
    }
  }
  h4{
    @extend .title-card;
    margin-bottom: 1.5rem;
    @apply .text-navy .pt-2;
  }
  P{
    @apply .text-grey;
    a{
      text-decoration: underline;
    }
  }
  ul{
    list-style: none;
    @apply .pb-5 .w-full ;
    li{
      @apply .flex .text-grey .mb-3 .w-full .block  ;
      vertical-align: top;
      padding-left: 1.4rem;
      position: relative;
      @media (max-width: 900px) {
        padding-left: 3.4rem;
        margin-bottom: 1.75rem;
      }
      @media (max-width: 700px) {
        padding-left: 5.4rem;
        margin-bottom: 3.75rem;
      }
      &::before{
        @apply .text-pink .pb-4 .float-left;
        height: 2rem;
        content: "•";
        margin-right: 1rem;
        font-size: 2.2rem;
        left: -0.2rem;

        line-height: 0;
        padding-top: 0.6rem;
        position: absolute;
        @media (max-width: 900px) {
          margin-bottom: 6rem;
          font-size: 5rem;
          top: 1rem;
        }
        @media (max-width: 700px) {
          margin-bottom: 6rem;
          font-size: 8rem;
          top: 1.2rem;
        }
      }
      ul{
        list-style: none;
        @apply .py-4 .pl-6 .w-full .mb-3;
        li{
          @apply .flex  .text-grey .mb-3 .w-full .block .float-left;
          vertical-align: top;
          &::before{
            @apply .text-pink .pb-4 .float-left;
            content: "•";
            margin-right: 1rem;
            height: 2rem;
            font-size: 2rem;
            line-height: 0;
            padding-top: 0.6rem;
          }
        }
      }
      a{
        text-decoration: underline;
      }
    }
  }
  .phone{
    @extend .title-general-sm;
    margin-bottom: 1.5rem;
    @apply .text-blue .pt-2;
    text-decoration: underline;

    @media (max-width: 900px) {
      margin-bottom: 4rem;
    }
    &:before{

    }
  }
  &.content-text-white{
    h1, h2, h3, h4, P{
      @apply .text-white;
    }
    li{
      @apply .text-white;
      &::before{
        @apply .text-white;
      }
    }
  }
}


.ticklist{
  list-style: none;
  @apply .pb-10;
  li{
    @apply .text-grey;
    display: flex;
    vertical-align: top;
    margin-bottom: 0.1rem;

    @media (max-width: 900px) {
      margin-bottom: 0.5rem;
    }
    @media (max-width: 700px) {
      margin-bottom: 0.7rem;
    }

    .icon{
      @media (max-width: 1300px) {
        padding-top:.2rem;
      }
      svg{
        @apply .w-8 .h-8 .overflow-visible .fill-current .text-navy;
        @media (max-width: 900px) {
          @apply .w-16 .h-16 ;
        }
        @media (max-width: 900px) {
          @apply .w-20 .h-20 ;
        }
      }
    }
    .link-name{
      @apply .font-semibold .text-grey;
      font-size: 1.05rem;
      line-height: 1.6;

      @media (max-width: 1300px) {
        font-size: 1.3rem;
      }

      @media (max-width: 900px) {
        font-size: 2.5rem;
      }
      @media (max-width: 700px) {
        font-size: 3rem;
      }
    }

  }
}
