.slideshow{
  overflow: hidden;
  @media (min-width: 1500px) {
    height: 33vw;
  }

}

.bannertextarea{
  margin-left: 14%;
  padding-right: 10%;
  width: 86%;
  height: 100%;
  @media (min-width: 1300px) {
    padding-right: 10%;
  }
  @media (min-width: 1500px) {
    margin-left:calc(100% - 650px;);
    width: 650px;
    padding-right: 10%;
  }
  @media (max-width: 900px) {
    @apply .order-2 .pt-32 .pb-40;
    margin: 0 auto;
    width: 86%;
    padding-right: 0;
  }
  @media (max-width: 700px) {
    @apply .pt-40 .pb-48;

  }
}

.imgarea{
  @media (max-width: 900px) {
    @apply .order-1;
  }
}

.slider-nav-position {
  position: absolute;
  bottom: 3rem;
  z-index: 100;
  height: 20px;
  margin: 0 auto;
  margin-left: 7%;
  @media (min-width: 1500px) {
    margin-left: calc(100% / 2 - 650px );
  }


}

.controls {
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
  position: absolute;
  right: 6rem;
  bottom: 14rem;
  }
  &.property-controls{
    position: relative;
    width: 100%;
    @media (max-width: 900px) {
      position: relative;
      width: 100%;
      bottom: inherit;
      right: auto;
    }
  }
  .slider-nav-position {
    position: absolute;
    top: 16%;
    text-align: left;
    color: red;
    margin-left: 7%;
    @media (max-width: 1020px) {
      top: 16%;
    }


  }
  .carouselpagination {
    width: fit-content;
    ul.slick-dots {
      position: static;
      bottom: 0;
      line-height: 0;
      padding-top: 4px;
      li {
        padding: 0;
        margin: 0 0.3rem;
        height: 0.8rem;
        width: 0.8rem;
        display: inline-block;
        position: relative;
        background: none;
        @media (max-width: 900px) {
          height: 3rem;
          width: 3rem;
        }
        &:active {
          top: 1px;
        }
        button {
          height: 10px;
          width: 10px;
          border-radius: 10px;
          overflow: hidden;
          border: 1px solid #979797;
          background: white;
          margin: 0;
          cursor: pointer;
          &:before {
            display: none;
          }
          @media (max-width: 900px) {
            height: 2rem;
            width: 2rem;
          }
        }
        &.slick-active {
          button {
            @apply .bg-pink;
          }
        }
      }
    }
  }

}
