.btn {
  @apply .bg-pink .text-white .uppercase .inline-block  .rounded .font-extrabold;
  padding: 0.8rem 1.5rem;
  font-size: 0.9rem;
  @media (max-width: 900px) {
    padding: 1.8rem 2.4rem;
    font-size: 2.4rem;
    border-radius: 0.5rem;
    @apply .font-bold;
  }
  @media (max-width: 700px) {
    padding: 2.2rem 3.4rem;
    font-size: 3.2rem;
  }
  &:focus {
    outline:none;
  }
  &:hover {
    background-color:rgba(#EE1D8F, 0.9);
  }
}

.btn-pagination{
  @apply  .text-white .inline-block .rounded .font-extrabold;
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  @media (max-width: 900px) {
    display: block;
    width: 100%;
    font-size: 2.4rem;
    padding: 1.8rem 2.4rem;
    border-radius: 0.5rem;
    @apply .font-bold;
  }
  @media (max-width: 700px) {
    font-size: 3.2rem;
  }
}

.btn-search {
  @apply .bg-pink .text-white .uppercase .inline-block .rounded-r .font-extrabold;
  padding: 0.8rem 1.5rem;
  font-size: 0.9rem;
  width:19%;
  @media (max-width: 900px) {
    display: block;
    width: 100%;
    font-size: 2.4rem;
    border-radius: 0.5rem;
    padding: 1.8rem 2.4rem;
    @apply .font-bold;

  }
  @media (max-width: 700px) {
    font-size: 3.4rem;
  }
}

.btn-navy {
  @apply .bg-navy .text-white .uppercase .inline-block .rounded .font-extrabold;
  padding: 0.8rem 1.5rem;
  font-size: 0.9rem;
  @media (max-width: 900px) {
    font-size: 2.4rem;
    border-radius: 0.5rem;
    padding: 1.8rem 2.4rem;
    @apply .font-bold;

  }
  @media (max-width: 700px) {
    font-size: 3.4rem;
  }
}

.btn_underline{
  @apply .underline .font-semibold	.text-sm;
  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
  @media (max-width: 700px) {
    font-size: 3.2rem;
  }
}

.btn-download {
  @apply .bg-pink  .uppercase .rounded .text-white ;
  color: #fff;
  padding: 0.8rem 1.5rem;
  font-size: .85rem;
  @media (max-width: 900px) {
    padding: 1.8rem 2.4rem;
    font-size: 2.4rem;
    border-radius: 0.5rem;
    @apply .font-bold;
  }
  @media (max-width: 700px) {
    padding: 2.2rem 3.4rem;
    font-size: 3.2rem;
  }
  svg{
    @apply .w-6 .h-6 .overflow-visible .fill-current .text-white;
    @media (max-width: 900px) {
      height: 4rem;
      width: 4rem;
    }
  }
}

.btn-category {
  @apply .bg-lightblue .capitalize .rounded .font-bold .text-navy .inline-block  ;
  padding: 0.6rem .8rem;
  margin-bottom: .6rem;
  font-size: .75rem;
  @media (max-width: 900px) {
    font-size: 1.8rem;
    border-radius: 0.5rem;
    padding: 1.8rem 2.4rem;
    @apply .font-bold;
    margin-bottom: 1.6rem;

  }
  @media (max-width: 700px) {
    font-size: 2.8rem;
  }
  &.bck-filter{
    @apply .bg-blue .text-white;
  }
  &.active{
    @apply .bg-pink .text-white .underline;
  }
}

.btn-menu {
  @apply .bg-pink .rounded .text-white .font-body ;
  color: #fff;
  padding: 0.6rem .7rem;
  font-size: .8rem !important;
  &:focus{
    color: #fff !important;
  }
  &:hover {
    background-color:rgba(#EE1D8F, 0.9);
  }
}

.buttons-container{
  margin-left: -.5rem;

    a{
      margin-left: .5rem;
      display: inline-block;
    }

  &.marginbtm{
    a{
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 900px) {
    margin-top: 6rem;

  }
  a{
    margin-left: .5rem;
    display: inline-block;
  }
  &.cta{
    a{
      min-width: 18rem;
      @media (max-width: 900px) {
        width:100%;
        margin-bottom: 3rem;

      }
    }
  }

}

.cta{
  .buttons-container{
    a{
      min-width: 18rem;
      @media (max-width: 900px) {
        width:100%;
        margin-bottom: 3rem;
      }
    }
  }
}

.sidebar_btns{
  .buttons-container{
    a{
    width:100%;
    margin-bottom: 1rem;
    margin-left: 0;
    text-align: center;
    }
  }
}

.social-circle {
    font-weight: 700;
    border-radius: 50%;
    overflow: hidden;
    color: #20323f;
    text-align: center;
    font-family: "filson-soft";
    width: 3rem;
    height: 3rem;
    border: .2rem #20323f solid;
    line-height: 2.6rem;
    font-size: 1.6rem;
}

.icon-size{
  @apply .overflow-visible;
  width: 2.2rem;
  height: 2.2rem;
  @media (max-width: 900px) {
    width: 4rem;
    height: 4rem;
  }
  @media (max-width: 700px) {
    width: 6rem;
    height: 6rem;
  }
}

.btn-text{
  @apply .underline .font-semibold	.text-sm;
  @media (max-width: 900px) {
    font-size: 2.1rem;
    padding: 2rem 0;
  }
  @media (max-width: 700px) {
    font-size: 3rem;
    padding: 2rem 0;
  }
}

.btn-minwidth{
  min-width: 15rem;
}

.btn-categories{
  @apply .flex .align-middle .justify-start .mb-2 .px-1;
  @media (max-width: 900px) {
    @apply .px-2 .mx-1 .mb-4 ;
  }
  .icon{
    @apply .text-white .rounded-l .p-2;
    @media (max-width: 900px) {
      padding: 1.4rem;
      border-radius: .7rem 0 0 .7rem ;
    }
    svg{
      @apply .w-4 .h-4 .overflow-visible .fill-current .text-white;
      @media (max-width: 900px) {
        @apply .w-10 .h-10;
      }
      @media (max-width: 700px) {
        @apply .w-12 .h-12;
      }
    }
  }
  .cat-title-navy{
    @apply .bg-navy .text-white .block .rounded-r .font-semibold .text-sm .py-1 .px-3;
    @media (max-width: 900px) {
      font-size: 2rem;
      padding: 1rem 2rem;
      border-radius:0 .7rem .7rem 0;
    }
    @media (max-width: 700px) {
      font-size: 3rem;
      padding: 1rem 2rem;
    }
  }
}

.btn-categories-details{
  @apply .flex .align-middle .justify-start .px-1;
  @media (max-width: 900px) {
    @apply .px-2 .mx-1 .mb-4 ;
  }
  .icon{
    @apply .text-white .rounded-l .p-2;
    @media (max-width: 900px) {
      padding: 1.4rem;
      border-radius: .7rem 0 0 .7rem ;
    }
    svg{
      @apply .w-4 .h-4 .overflow-visible .fill-current .text-white;
      @media (max-width: 900px) {
        @apply .w-10 .h-10;
      }
      @media (max-width: 700px) {
        @apply .w-12 .h-12;
      }
    }
  }
  .cat-title-navy{
    @apply .bg-lightblue .text-navy .block .rounded-r .font-semibold .text-sm .py-1 .px-3;
    @media (max-width: 900px) {
      font-size: 2rem;
      padding: 1rem 2rem;
      border-radius:0 .7rem .7rem 0;
    }
    @media (max-width: 700px) {
      font-size: 3rem;
      padding: 1rem 2rem;
    }
  }
}
