header {
  background-color: white;
  position: relative;
  z-index: 1000;
  .nav-logo {
    width: 17%;
    @media (max-width: 960px) {
      width: 30%;
    }
    @media (max-width: 800px) {
      width: 60%;
    }
    .logo {
      width: 100%;
      img {
        max-width: 190px;
        width: 70%;
      }
    }
  }
  .nav-right{
    width: 83%;
    @media (max-width: 960px) {
      display: none;
    }
    ul {
      @media (max-width: 1030px) {
        width: 100%;
      }


      li {
        font-family: $title-font-family;
        a {
          font-size: 0.85rem;
          @media (max-width: 1300px) {
            font-size: 0.95rem;
          }
          @media (max-width: 1100px) {
            font-size: 1rem;
          }


          &[href="/order-now"] {
            background-color: #e72076;
            color: white;
            border-radius: 100px;
            padding: 0.75rem 1.3rem;
          }
          &:focus{
            @apply .text-blue ;
          }
          &.active{
            @apply .text-pink ;
          }
        }
        i {
          top: -3px;
          margin-left: .3rem;
        }

        .active-arrow{
          position: relative;
          width: 100%;
          height: 0;
          .arrow{
            padding: 10px;
            right: -1.1rem;
            top: 1.65rem;
            padding: 0;
            width: 20px;
            position: absolute;
            display: block;
            &:before {
              position: absolute;
              content: " ";
              display: inline-block;
              width: 16px;
              height: 16px;
              left: calc(50% - 18px);
              top: -8px;
              z-index: 100;
              background: #EBF8FE;
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
              transform: rotate(45deg);
            }
            &:after {
              content: " ";
              position: absolute;
              width: 30px;
              height: 1.5rem;
              z-index: 150;
              top: 0;
              left: calc(50% - 25px);
              background: #EBF8FE;
            }
            @media (max-width: 1100px) {
              display: none;
            }
          }
        }


        .subMenu {
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.28);
          background: #EBF8FE;
          left: 8%;
          top: 6.5rem;
          width: 84%;
          transform: translateZ(0);
          transition: all 1s;
          border-radius: 0.4rem;
          min-height: 16rem;

          &.items-one{
            width: 43%;
            left: 24%;
            &.dev-link{
              left: 38%;
              @media (max-width: 1100px) {
                left: 40%;
              }
            }
            &.contact-link{
              left: 60%;
            }
            &.careers-nav{
              left: 53%;
              @media (max-width: 1300px) {
                left: 55%;
              }
            }
          }
          &.items-two{

            width: 64%;
            left: 16%;

          }

          &.items-three{
            left: 8%;
            width: 84%;
          }
          &.contact-nav{
            left: 63%;
            width: 25%;
          }


          h4 {
            a {
              font-size: 1.1rem;
              @media (max-width: 1400px) {
                font-size: 1.3rem;
              }
              @media (max-width: 1250px) {
                font-size: 1.5rem;
              }
            }
          }

          .third-level-menu{
            margin-bottom: 1.5rem;
            align-self: flex-start;
            .sub-menu-title{
              font-size: 1.1rem;
              line-height: 1.25;
              margin-bottom: .5rem;
              float: left;
              width: 100%;
              @apply .font-semibold .font-title .text-pink .uppercase;
            }
            li{
              border-bottom: 1px solid #B6CDDA;
              padding: .7rem 0;
              float: left;
              width: 100%;
              a{
                font-size: .92rem;
                line-height: 1.25;
                @apply .font-semibold .font-body .text-navy .normal-case;
              }

              &:last-child{
                border-bottom: 1px solid #B6CDDA;
              }
              &.menu-sub-item-link{
                border-bottom: none;
                a{
                  font-size: .9rem;
                  line-height: 1.25;
                  @apply .font-bold .font-body .text-blue .lowercase .underline;
                }
              }

            }
          }

          .nav-highlight-area{

            padding: 2rem;
            position: absolute;
            right: 0;
            height: 100%;
            overflow: hidden;
            border-radius: 0 0.4rem 0.4rem 0;
            .bckimg{
              background-image: url("/assets/img/bck_nav.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              position: absolute;
              top: 0; left: 0;
              width: 100%; height: 100%;
              z-index: 1;
              filter: grayscale(100%);

            }
            .overlay{
                background-color: #0a2e4c;
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                z-index: 2;
                opacity: .75;
            }
            h4, .btn-menu{
              z-index: 5;
              position: relative;

            }
            h4{
              margin-bottom: 1rem !important;
            }
          }

          .nav-links{
            padding: 2.4rem 2.4rem 0 0;
            position: absolute;
            right: 0;
            height: 100%;
            overflow: hidden;
            border-radius: 0 0.4rem 0.4rem 0;
          }

        }
      }
    }
    .social{
      @media (max-width: 1030px) {
        display: none;
      }
      .facebook{
      }
      .twitter{

      }
      .insta{
        @media (max-width: 1180px) {
          display: none;
        }
      }

      .search{
        border-radius: 80%;
        width: 2.2rem;
        height: 2.2rem;
        @apply .border-blue .bg-pink;
        padding: .5rem;
        img{
          width: 100%;
        }
      }
    }

  }
}

.mobile-burger-btn {
  cursor: pointer;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  z-index: 100;
  border-radius: 10rem;
  display: inline-block;
  position: absolute;
  right: 7.5%;
  top: 3.5rem;
  z-index: 8888;
  display: none;
  @media (max-width: 960px) {
    display: block;
    top: 3.5rem;
  }
  @media (max-width: 900px) {
    top: 6rem;
  }
  i {
    font-size: 22px;
    position: relative;
    color: #1d3248;
    @media (max-width: 960px) {
      background-color: #e72076;
      border-radius: 10rem;
      color: #fff;
      display: flex;
      padding: 10px 20px;
      text-transform: uppercase;
    }
    &:nth-child(2) {
      display: none;
    }
    b {
      display: none;
      font-family: $title-font-family;
      font-size: 14px;
      padding: 4px 0 2px 10px;
      @media (max-width: 960px) {
        display: block;
      }
    }
  }
  &.menu-open {
    right: 5%;
    position: fixed;
    @media (max-width: 600px) {
      right: 9.5%;
    }
    i {
      color: #ffffff;
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
  &.notification_active{
    @media (max-width: 960px) {
      top: 14rem;
    }
  }
}
